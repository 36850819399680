// Constants file

const GOOGLE_API_KEY = process.env.NODE_ENV==='production'? process.env.REACT_APP_GOOGLE_API_KEY : ''; 
const HASURA_GRAPHQL_ENGINE_HOSTNAME = 'graphql.thinair.co';

const scheme = (proto) => {
  return window.location.protocol === 'https:' ? `${proto}s` : proto;
}

const wsurl = `${scheme('ws')}://${HASURA_GRAPHQL_ENGINE_HOSTNAME}/v1/graphql`;
const httpurl = `${scheme('http')}://${HASURA_GRAPHQL_ENGINE_HOSTNAME}/v1/graphql`;

export {
  GOOGLE_API_KEY,
  wsurl,
  httpurl,
};
