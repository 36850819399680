import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import { GOOGLE_API_KEY } from '../constants';
import ArrowIcon from "../assets/ArrowIcon.png";

const VehicleMarker = React.memo(({ lat, lng, heading }) => {
  return (
    <div style={{ transform: `translate(-50%, -50%) rotate(${heading}deg)` }} className="marker-container">
      <img src={ArrowIcon} alt="marker" className="marker-image" />
    </div>
  );
});

function createMapOptions(maps) {
  return {
    mapTypeControlOptions: {
      position: maps.ControlPosition.TOP_LEFT
    },
    mapTypeControl: true,
    draggable: true,
    rotateControl: true,
    scaleControl: true,
    streetViewControl: true,
    panControl: true,
    gestureHandling: 'greedy'
  };
}

function MapContainer(props) {
  const [latlng, setLatLng] = useState(props.marker_location || {});
  const [map, setMap] = useState(null);

  useEffect(() => {
    if (props.marker_location) {
      setLatLng(props.marker_location);
      if (map) {
        map.panTo(props.marker_location);
      }
    }
  }, [props.marker_location, map]);

  if (!latlng) {
    return <div>Loading...</div>; 
  }

  return (
    <div className="map_wrapper">
      <GoogleMapReact
        bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
        defaultCenter={props.marker_location}
        //center={props.marker_location} // Use center prop to update the location dynamically
        defaultZoom={11}
        layerTypes={['TrafficLayer']}
        options={createMapOptions}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => {
          setMap(map);
          if (props.marker_location) {
            map.setCenter(props.marker_location);
          }
        }}
      >
        <VehicleMarker
          lat={latlng.lat}
          lng={latlng.lng}
          heading={props.heading}
        />
      </GoogleMapReact>
    </div>
  );
}

export default MapContainer;
