import React, { useState, useEffect } from 'react';
import { gql, useQuery, ApolloProvider } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import client from '../apollo';
import App from '../App/App';
import UserInfo from '../UserInfo/UserInfo';
import '../Vehicle/Vehicle.css';

const GET_VEHICLE = gql`
  query getVehicle($vehicleId: String!) {
    api_vehicle (where: { id: { _eq: $vehicleId }}) {
      id
      name
      expiry_hours
    }
  }
`;

const Vehicle = () => {
  const [vehicleId, setVehicleId] = useState(uuidv4());
  const [isLoading, setIsLoading] = useState(false);
  const [startTracking, setStartTracking] = useState(false);

  const { data } = useQuery(GET_VEHICLE, {
    variables: { vehicleId: vehicleId },
  });

  const handleTrackLocationClick = () => {
    setIsLoading(true);
    if (data?.api_vehicle?.[0]?.id) {
      setIsLoading(false);
      setStartTracking(true);
    }
  };

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get('id');
    if (id) {
      setVehicleId(id);
    }
  }, []);

  const Footer = () => {
    return (
      <footer className="App-footer">
        <a href="https://www.thinair.co" target="_blank" rel="noopener noreferrer">
          <img alt="thinair logo" src="https://www.thinair.co/wp-content/themes/thinair/images/logo.png" width="135" />
        </a>
        &nbsp; | &nbsp;
        <a href="https://www.thinair.co/fleet-management/" target="_blank" rel="noopener noreferrer">
          Fleet Management
        </a>
        &nbsp; | &nbsp;
        <a href="https://www.thinair.co/hours-of-service/" target="_blank" rel="noopener noreferrer">
          ELD Compliance
        </a>
        &nbsp; | &nbsp;
        <a href="https://www.thinair.co/contact-us/" target="_blank" rel="noopener noreferrer">
          Contact Us
        </a>
        <div className="footer-small-text">
        </div>
      </footer>
    );
  }

  return (
    <div className="App">
      <header className="App-header">
        <h1 className="App-title">LiveShare: Real-time Location Tracking</h1>
      </header>
      <div className="App-content container">
        {startTracking ?
          [
            <div key={'1'} className="list_of_vehicles">
              <div>
              <h4 style={{marginTop: '.5rem'}}><b>Vehicle:</b> {data.api_vehicle[0].name}</h4>
              </div>
              <div className="vehicle_info">
                (This vehicle is generating live location events)
              </div>
            </div>,
            <App key='2' vehicleId={vehicleId} />
          ]
          :
          <UserInfo userId={vehicleId} handleTrackLocationClick={handleTrackLocationClick} isLoading={isLoading} />
        }
      </div>
      <Footer />
    </div>
  );
}

const ApolloWrappedComponent = () => {
  return (
    <ApolloProvider client={client}>
      <Vehicle />
    </ApolloProvider>
  );
};

export default ApolloWrappedComponent;